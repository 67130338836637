// Imports
@import 'modules/_fonts';
@import 'modules/_all';
// END Imports

* {
    padding: 0;
    margin: 0;
}

a {
    text-decoration: none;
    color: $main-link;
}

a,
button {
    cursor: pointer;
    @include fade;
}

html, body {
    height: 100%;
}

body {
    font: 100% $OpenSans-Light, $font;
    color: $dark-gray-w1;
    font-size: 14px;
}

img {
    max-width: 100%;
    border: none;
}

img,
input,
textarea,
select,
a,
button {
    border: none;
    background-color: $transparent;
}

img:focus,
input:focus,
textarea:focus,
select:focus,
a:focus,
button:focus {
    outline: none;
}

figure {
    margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
    font-weight: 100;
}

.main {
    @extend .dd-normal;

    .box {
        @extend .dd-normal-box;
    }
}

$position: '#position';
$top: '#top';

.all-center {
    #{ $position }: absolute;
    #{ $top }: 50%;
    display: table-cell;
    vertical-align: middle;
}

.center {
    #{ $position }: relative;
    #{ $top }: -50%;
}

// WRAPPER
#dd-wrapper {
    position: relative;
    min-height: 100%;
}

.dd-wrapper {
    padding-bottom: 100px;
}

////GENERAL
.dd-fade {
    @include fade;
}

//PAGINATION
.dd-pagination {
    .pagination {
        font-size: 0;
        text-align: center;

        li {
            font-family: $OpenSans-Semibold;
            display: inline-block;
            font-size: 14px;
            padding: 4px;
        }

        li a {
            color: $dark-gray-w1;

            &:hover {
                color: $blue;
            }
        }

        li.active {
            color: $blue;
        }

        li.disabled {
            color: $dark-gray-w3;
        }
    }
}

//HEADER PAGES
.dd-header--page {
    margin-bottom: 60px;

    @media screen and (max-width: $mobile) {
        margin-bottom: 30px;
    }

    .dd-normal-box {
        background-image: url(/assets/img/site/header-shadow.png);
        background-position: center top;
        background-repeat: no-repeat;
        background-size: contain;
        padding: 28px 0;

        @media screen and (max-width: $mobile) {
            background-image: none;
        }
    }

    h1 {
        color: $white;
        font-family: $Raleway-Light;
        text-transform: uppercase;
        font-size: 30px;
        width: 50%;
        padding-right: 10px;

        @media screen and (max-width: $mobile) {
            width: 100%;
            text-align: center;
        }
    }

    .dd-path {
        width: 50%;
        right: 0;
        top: 50%;
        margin-top: -11px;
        text-align: right;

        @media screen and (max-width: $mobile) {
            display: none;
        }

        li {
            border-right: 1px solid $white;
            line-height: 13px;
            padding: 0 10px;
            font-size: 14px;
            margin-bottom: 7px;
        }

        li:first-child {
            padding-left: 0;
        }

        li:last-child {
            padding-right: 0;
            border-right: 0;
        }

        a {
            color: $white;
        }
    }
}

// POSITIONS
.dd-isrelative {
    position: relative;
}

.dd-isabsolute {
    position: absolute;
}

.dd-isblock {
    display: block;
}

//COLORS
.dd-c-white {
    color: $white;
}

.dd-bg-yellow {
    background-color: $yellow;
}

.dd-bg-blue {
    background-color: $blue;
}

// TITLES/TEXTS
.dd-title-sec-df {
    font-family: $Raleway-Regular;
    text-transform: uppercase;
    color: $dark-gray-w2;
    font-size: 34px;
    letter-spacing: 0.02em;
    text-align: center;
    margin-bottom: 10px;
}

.dd-subtitl-sec-df {
    font-family: $Raleway-Regular;
    font-size: 16px;
    text-align: center;
    color: $dark-gray-w3;
    position: relative;
    padding-bottom: 28px;

    &::after {
        background-image: url(/assets/img/site/detail-sub-title.png);
        background-repeat: no-repeat;
        background-position: center center;
        content: "";
        width: 100px;
        height: 2px;
        position: absolute;
        left: 50%;
        margin-left: -50px;
        bottom: 0;
    }
}

.dd-title-page {
    @extend .dd-title-sec-df;
    font-size: 18px;
    text-align: left;
    letter-spacing: 0px;
    position: relative;
    padding-bottom: 19px;
    margin-bottom: 23px;

    @media screen and (max-width: $mobile) {
        text-align: center;
    }

    &::after {
        background-image: url(/assets/img/site/detail-sub-title.png);
        background-repeat: no-repeat;
        background-position: left center;
        content: "";
        width: 100%;
        height: 2px;
        position: absolute;
        left: 0;
        bottom: 0;

        @media screen and (max-width: $mobile) {
            background-position: center center;
        }
    }
}

.dd-text-error {
    padding: 120px 0 100px 0;
}

//STRUCTURE
.dd-main-content {
    width: 100%;
}

.dd-show {
    display: inline-block !important;
}

//FORM
form:not(.dd-m-custom-form) {
    input[type="text"],
    input[type="email"],
    textarea,
    select {
        border-color: $gray;
        @include placeholder('color', $dark-gray-w3);
    }
}

form {
    input[type="text"],
    input[type="email"],
    textarea,
    select {
        border: 1px solid;
        background: $white;
        width: 100%;
        font-size: 13px;
        padding: 0 13px;
        @include fade;

        &:focus {
            border: 1px solid $blue;
        }
    }

    textarea {
        padding: 13px 13px;
        height: 115px;
    }

    input {
        height: 45px;
    }

    .dd-cont-field {
        select {
            height: 45px;
        }
        .dd-icon-field {
            background-color: $blue;
            color: $white;
            right: -1px;
            top: -1px;
            pointer-events: none;
        }
    }

    .dd-box-sel {
        .dd-icon-field {
            font-size: 14px;
            padding: 14px 9px;
        }
    }

    .dd-box-file {
        border: 1px solid $gray;
        margin-bottom: 4px;

        p {
            left: 13px;
            top: 12px;
            z-index: -1;
        }

        .dd-label {
            color: $blue;
            font-family: $OpenSans-Semibold;
            border-right: 1px solid $gray;
            padding-right: 11px;
            pointer-events: none;

            @media screen and (max-width: $mobile) {
                border-right: 0;
            }
        }

        input {
            @include calc('width', '100% - 150px');
            height: auto;
            padding: 13px 0 13px 150px;
            cursor: pointer;
            font-size: 11px;

            @media screen and (max-width: $mobile) {
                opacity: 0;
            }
        }

        .dd-icon-field {
            font-size: 19px;
            padding: 1px 8px;
            height: 100%;
            line-height: 48px;
        }
    }

    .dd-text-file {
        font-size: 10px;
        color: $dark-gray-w3;
    }

    .dd-line-form {
        width: 100%;
        margin-bottom: 15px;
    }

    .dd-col-2 {
        width: 50%;

        @media screen and (max-width: $tablet) {
            width: 100%
        }

        &:nth-child(1) {
            padding-right: 13px;

            @media screen and (max-width: $tablet) {
                padding-right: 0;
                margin-bottom: 15px;
            }
        }

        &:nth-child(2) {
            padding-left: 13px;

            @media screen and (max-width: $tablet) {
                padding-left: 0;
            }
        }
    }

    button {
        width: 100%;
        background-color: $blue;
        color: $white;
        font-size: 13px;
        text-transform: uppercase;
        padding: 11px 35px;
        border: 1px solid $light-gray;
    }

    .dd-container-btn-send {
        width: 133px;
        height: 42px;
        vertical-align: middle;
        text-align: center;

        @media screen and(max-width: $mobile) {
            margin: 0 auto;
            display: block;
        }
    }

    label {
        font-size: 13px;
        vertical-align: middle;

        @media screen and(max-width: $mobile) {
            width: 100%;
            text-align: center;
        }

        input {
            vertical-align: middle;
            margin-left: 23px;
            margin-right: 11px;
        }

        span {
            font-size: 13px;
            vertical-align: middle;
        }
    }

    .dd-feedback-form {
        margin-bottom: 15px;
        font-size: 12px;
    }

    .dd-loader {
        background-image: url(/assets/img/site/loader.gif);
        background-position: center center;
        background-repeat: no-repeat;
        width: 16px;
        height: 16px;
        margin-top: 14px;
    }
}

input.dd-error,
textarea.dd-error,
button.dd-error,
select.dd-error,
div.dd-error {
    border: 1px solid #c91919 !important;
}

//end FORM

//// END GENERAL

// MAIN HEADER
// ===========
//////////////

///////SECTION TOP///////
#dd-main-header {
    position: relative;
    z-index: 1000;
}

#dd-main-header .dd-section-top--header {
    border-bottom: 1px solid $gray;
    padding: 30px 0 21px 0;

    //Menu top
    .dd-menu-top {
        max-width: 445px;
        left: 0;
        top: 0;

        @media screen and (max-width: $large) {
            width: 40%;
        }

        @media screen and (max-width: $tablet) {
            top: 57px;
        }

        @media screen and (max-width: 730px) {
            display: none;
        }

        li {
            border-right: 1px solid #818181;
            line-height: 13px;
            padding: 0 9px;
            font-size: 12px;

            @media screen and (max-width: $large) {
                width: 50%;
                text-align: center;
                margin-bottom: 13px;
            }
        }

        li:first-child {
            padding-left: 0;
        }

        li:last-child {
            border-right: 0;
        }

        a {
            color: $dark-gray-w2;

            &:hover {
                color: $blue;
            }
        }
    }
    //end Menu top

    //Logo
    .dd-logo {
        width: 129px;
        margin: 0 auto;
        display: block;

        @media screen and (max-width: 730px) {
            margin: 0;
        }
    }
    //end Logo

    //Box tel
    .dd-box-tel {
        position: absolute;
        right: 0;
        top: 36px;

        @media screen and (max-width: $tablet) {
            top: 40px;
        }
    }

    .dd-box-tel .dd-text-number {
        color: $blue;
        font-family: $OpenSans-Semibold;
        font-size: 12px;
        letter-spacing: 0.04em;

        @media screen and (max-width: $mobile) {
            font-size: 11px;
        }
    }

    .dd-box-tel .dd-number {
        font-family: $OpenSans-Semibold;
        font-size: 28px;

        @media screen and (max-width: $mobile) {
            font-size: 20px;
        }
    }
    //end Box tel
}

/////// end SECTION TOP///////

//-------------------//

//Section bottom
#dd-main-header .dd-section-bottom--header {

    .dd-normal-box-menu {
        width: 1050px;
        margin: 0 auto;

        @media screen and (max-width: 1090px) {
            width: 90%;
        }
    }

    //Main menu
    .dd-main-menu {
        text-transform: uppercase;
        font-family: $Raleway-Regular;
        text-align: center;

        @media screen and (max-width: $tablet) {
            display: none;
        }

        li {
            position: relative;
        }

        li.dd-m-active {
            & > a {
                color: $blue;
            }

            a:before {
                background-color: $blue;
            }
        }

        li > a {
            color: $dark-gray-w2;
            padding: 23px 17px;
            font-size: 17px;
            display: block;
            position: relative;

            @media screen and (max-width: 1090px) {
                font-size: 13px;
                padding: 23px 15px;
            }

            @media screen and (max-width: 925px) {
                font-size: 11px;
                padding: 23px 10px;
            }

            &:before {
                content: "";
                position: absolute;
                left: 0;
                top: -1px;
                width: 100%;
                height: 2px;
                @include fade;
            }

            &:hover {
                color: $blue;

                &:before {
                    background-color: $blue;
                }
            }
        }

        .dd-sub {
            display: none;
            position: absolute;
            left: 0px;
            width: 225px;
            background: $white;
            text-align: left;
            z-index: 9999;
            top: 66px;
            list-style-type: none;
            @include border-radius(0 0 5px 5px);

            @media screen and (max-width: 1090px) {
                top: 61px;
            }

            @media screen and (max-width: 925px) {
                top: 59px;
            }

            li {
                &:last-child a {
                    border-bottom: 0 !important;
                }
            }
        }

        .dd-sub a {
            font-size: 14px;
            text-transform: uppercase;
            padding: 14px 16px;
            border-bottom: 1px solid $light-gray;

            @media screen and (max-width: 1090px) {
                font-size: 13px;
            }

            @media screen and (max-width: 925px) {
                font-size: 11px;
            }

            &:before {
                display: none;
            }
        }
    }
    //end Main menu

    //Main menu responsive
    .dd-responsive-nav {
        display: none;

        @media screen and (max-width: $tablet) {
            display: block;
        }

        .slicknav_menu {
            background-color: transparent;
            padding: 0;
            position: relative;

            .slicknav_icon-bar {
                background-color: $dark-gray-w2;
                box-shadow: none;
            }
        }

        .slicknav_nav {
            background-color: $white;
            position: absolute;
            width: 100%;
            left: 0;
            top: 63px;
            z-index: 1;
        }

        .slicknav_nav a {
            color: $dark-gray-w2;
            text-transform: uppercase;
            font-family: $Raleway-Regular;
            font-size: 14px;
            padding: 12px 10px;
        }

        .slicknav_nav a:hover {
            background: transparent;
            color: $blue;
        }

        .slicknav_nav li {
            border-bottom: 1px solid $light-gray;
        }

        .slicknav_btn {
            float: left;
            margin: 16px 0;
            padding: 0.46em .34em 0.32em .34em;
            border: 1px solid $dark-gray-w2;
            background-color: transparent;
            position: relative;

            &:before {
                content: 'Menu';
                position: absolute;
                text-transform: uppercase;
                top: 6px;
                left: 42px;
                font-family: $Raleway-Regular;
                font-size: 16px;
            }
        }
    }
    //end Main menu responsive
}

//end Section Top

// end MAIN HEADER
// ===============

//----------------------------//

// MAIN FOOTER
// ===============
.dd-menu-bottom {
    li {
        padding: 0 10px;
        position: relative;

        &:after {
            content: '';
            width: 1px;
            height: 15px;
            position: absolute;
            right: 0;
            top: 3px;
        }

        &:last-child {
            padding-right: 0;
            border-right: 0;

            &:after {
                display: none;
            }
        }
    }
}

//Section top
#dd-main-footer .dd-section-top--footer {
    padding: 72px 0 66px 0;

    //Title
    .dd-title-section {
        font-size: 22px;
        position: relative;
        padding-bottom: 15px;
        margin-bottom: 39px;
        text-transform: uppercase;
        letter-spacing: 0.03em;

        &::after {
            background-image: url(/assets/img/site/detail-title-footer.png);
            background-repeat: no-repeat;
            background-position: left center;
            content: "";
            width: 100px;
            height: 2px;
            position: absolute;
            left: 0;
            bottom: 0;

            @media screen and (max-width: 570px) {
                left: 50%;
                margin-left: -50px;
            }
        }
    }

    //Footer list
    .dd-footer-list {
        @media screen and (max-width: 860px) {
            margin-bottom: 43px;
        }

        & > li {
            width: 33.333%;
            vertical-align: top;

            @media screen and (max-width: 570px) {
                width: 100%;
                max-width: 320px;
                margin: 0 auto;
                display: block;
                text-align: center;
            }

            &:nth-child(1) {
                padding-right: 7%;

                @media screen and (max-width: $tablet) {
                    display: none;
                }
            }

            &:nth-child(2) {
                padding-left: 3%;

                @media screen and (max-width: $tablet) {
                    width: 50%;
                    padding-left: 0;
                }

                @media screen and (max-width: 570px) {
                    width: 100%;
                }
            }

            &:nth-child(3) {
                padding-left: 6%;

                @media screen and (max-width: $tablet) {
                    width: 50%;
                    padding-left: 0;
                }

                @media screen and (max-width: 570px) {
                    width: 100%;
                }

                p {
                    margin-bottom: 21px;
                }
            }
        }

        .box-form {
            input {
                height: 40px;
            }

            .dd-btn-send-email {
                background-color: $yellow;
                color: $black;
                float: right;

                @media screen and (max-width: 570px) {
                    width: 100%;
                    float: none;
                }
            }

            .dd-line-form {
                margin-bottom: 20px;
            }

            .dd-container-btn-send {
                float: right;

                @media screen and (max-width: 570px) {
                    width: 100%;
                    float: none;
                }
            }

            button {
                border: 0;
            }

            button:hover {
                background-color: $dark-gray-w2;
                color: $white;
            }
        }

        .dd-success {
            color: $yellow;
            width: 100%;
            text-align: center;
            display: inline-block;
            font-weight: bold;
        }

        .dd-error {
            width: 100%;
            text-align: center;
            display: inline-block;
            font-weight: bold;
        }
    }
    //end Footer list

    .dd-text {
        font-family: $Raleway-Light;
        color: $white;
        font-size: 13px;
        line-height: 23px;
        margin-bottom: 30px;
    }

    .dd-contact-list {
        font-size: 13px;
        color: $white;
        text-align: left;

        a {
            color: $white;
        }

        @media screen and (max-width: 570px) {
            max-width: 230px;
            margin: 0 auto;
        }

        li {
            margin-bottom: 26px;
        }

        .fa {
            width: 13px;
            text-align: center;
            color: $yellow;
            font-size: 15px;
            margin-right: 20px;
        }

        span {
            @include calc('width', '100% - 33px');
            font-size: 13px;
            line-height: 23px;
        }
    }

    .dd-menu-bottom {
        vertical-align: middle;
        display: none;

        @media screen and (max-width: 860px) {
            width: 100%;
            display: block;
            text-align: center;
        }

        li {
            padding: 0 15px;
            position: relative;

            @media screen and (max-width: $mobile) {
                padding: 0 10px;
            }

            a {
                color: $white;
                font-size: 14px;

                @media screen and (max-width: $mobile) {
                    font-size: 12px;
                }
            }

            &:after {
                background-color: $white;

                @media screen and (max-width: $mobile) {
                    height: 12px;
                }
            }
        }
    }
}

//end Section top

//Section bottom

#dd-main-footer .dd-section-bottom--footer {
    background-color: #FFF;

    .dd-normal-box {
        padding: 23px 0 30px 0;
    }

    .dd-box-dev {
        vertical-align: middle;
        width: 50%;

        @media screen and (max-width: 860px) {
            width: 100%;
            text-align: center;
        }

        p {
            color: $dark-gray-w2;
            font-size: 14px;
            vertical-align: middle;
            margin-right: 7px;
        }
    }

    .dd-din-logo {
        background-image: url(/assets/img/site/logo-dindigital.png);
        background-position: center center;
        background-repeat: no-repeat;
        width: 68px;
        height: 22px;
        vertical-align: middle;

        @media screen and (max-width: 398px) {
            margin-top: 10px;
        }
    }

    .dd-menu-bottom {
        vertical-align: middle;
        float: right;
        width: 50%;
        text-align: right;

        @media screen and (max-width: 860px) {
            display: none;
        }
    }

    .dd-menu-bottom li {
        a {
            color: $dark-gray-w2;
            font-size: 13px;

            &:hover {
                color: $blue;
            }
        }

        &:after {
            background-color: $dark-gray-w2;
        }
    }
}

//end Section bottom

//CK CONTENT
.dd-ck-content {
    color: $dark-gray-w1;
    font-size: 14px;

    h1, h2, h3, h4, h5, h6 {
        font-family: $Raleway-Regular;
        margin-bottom: 10px;
    }

    a {
        color: $blue;
        width: initial !important;
        height: initial !important;

        &:hover {
            color: $dark-gray-w3;
        }
    }

    p {
        margin-bottom: 12px;
    }

    strong {
        font-family: $OpenSans-Semibold;
    }

    img {
        padding: 10px;
    }

    ul {
        list-style-position: inside;
    }
}

.dd-description-inst {
    font-family: $OpenSans-Semibold;
    font-size: 15px !important;
    margin-bottom: 15px !important;
    border-bottom: 1px solid $gray;
    padding-bottom: 15px;
}

.dd-border-reader {
    border-left: 1px solid $gray;
    border-right: 1px solid $gray;
    border-top: 1px solid $gray;
}

//LB
.lb-data .lb-close {
    background-image: url(/assets/img/site/close.png) !important;
}

.lb-nav a.lb-prev {
    background-image: url(/assets/img/site/prev.png) !important;
}

.lb-nav a.lb-next {
    background-image: url(/assets/img/site/next.png) !important;
}

.lb-cancel {
    background-image: url(/assets/img/site/loading.gif) !important;
}

//end Template
//////////////////
